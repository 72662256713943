<!--
 * @Description: ==================  =================
 * @Date: 2022-05-12 11:37:41
 * @Author: Zero
 * @FilePath: \beian-web\src\components\Section\S2.vue
 * @LastEditors: Zero
 * @LastEditTime: 2023-06-25 15:35:20
-->
<template>
  <section id="content-section6" class="content-section6">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="content-iphone">
            <img class="img-responsive" src="assets/people1.png" alt="iphone" />
          </div>
        </div>
        <div class="col-md-5 col-md-offset-1">
          <div class="single-content">
            <h3 class="border-bottom">关于我们</h3>
            <p>
              深圳市一沙一粟科技有限公司是一家游戏发行运营商，为全球用户提供：海外引进大型客户端网游、精品手游，中国区发行运营、中国研发次世代手机网游全球发行。旗下游戏有战神传世、三国萌将等。
            </p>
            <ul class="list-inline">
              <li><i class="fa fa-facebook-official"></i></li>
              <li><i class="fa fa-twitter"></i></li>
              <li><i class="fa fa-google-plus"></i></li>
              <li><i class="fa fa-dribbble"></i></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
</template>
