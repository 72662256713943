<!--
 * @Description: ==================  =================
 * @Date: 2022-05-12 11:37:41
 * @Author: Zero
 * @FilePath: \beian-web\src\components\Section\S10.vue
 * @LastEditors: Zero
 * @LastEditTime: 2022-05-12 11:44:10
-->
<template>
  <section id="form2" class="form2">
    <div class="container">
      <div class="row">
        <div class="contact-overlay">
          <div class="col-md-offset-3 col-md-6 text-center">
            <h1 class="section-title border-bottom">联系我们</h1>
            <p class="section-para">深圳市福田区莲花街道紫荆社区深南大道6006号华丰大厦1806-C58</p>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="sr-only">姓名</label>
                  <input name="name" class="form-control" placeholder="姓名：" type="text" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="sr-only">邮箱</label>
                  <input name="email" class="form-control" placeholder="邮箱：" type="email" />
                </div>
              </div>
              <div class="col-sm-12 ">
                <div class="form-group">
                  <label class="sr-only">留言</label>
                  <textarea class="form-control textarea" name="message" rows="5" placeholder="留言："></textarea>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <button type="submit" class="btn btn-default">发送</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <ul class="list-inline social-links text-center">
              <li><i class="fa fa-facebook-official"></i></li>
              <li><i class="fa fa-twitter"></i></li>
              <li><i class="fa fa-google-plus"></i></li>
              <li><i class="fa fa-dribbble"></i></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
