<!--
 * @Description: ==================  =================
 * @Date: 2022-05-12 11:37:41
 * @Author: Zero
 * @FilePath: \beian-web\src\components\Section\S8.vue
 * @LastEditors: Zero
 * @LastEditTime: 2022-05-12 11:42:47
-->
<template>
  <section class="faq">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-md-offset-2 text-center">
          <h1 class="section-title">常见问题</h1>
          <p class="section-para">无缝衔接产品、设计、研发流程，降低沟通成本，缩短开发周期，提高工作效率</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="collapse-panel">
            <div id="accordion" class="panel-group" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default wow slideInRight" data-wow-delay="0.2s">
                <div id="headingOne" class="panel-heading" role="tab">
                  <h4 class="panel-title">
                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="/#1" aria-expanded="true" aria-controls="1">FAQ</a>
                  </h4>
                </div>
                <div id="1" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                  <div class="panel-body">
                    <p>免费版本限制</p>
                    <p>项目数量：10个
                      每个项目中设计图数量：300张
                      团队成员：1000人 设计协作席位：10人 超级文档席位：1000人
                      若设计协作成员数量或项目数量已超出10个，将无法新增任何内容，但可以继续查看。
                      （新增内容包括：上传设计图，新建文字卡片，上传文档等。）</p>
                  </div>
                </div>
              </div>
              <div class="panel panel-default wow slideInRight" data-wow-delay="0.4s">
                <div id="headingTow" class="panel-heading" role="tab">
                  <h4 class="panel-title">
                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="/#2" aria-expanded="false" aria-controls="2">服务协议</a>
                  </h4>
                </div>
                <div id="2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTow">
                  <div class="panel-body">
                    <p>We love to help your business, consectetur adipisicing elit. Dolore rerum ratione
                      quisquam,
                      nisi nulla hic omnis eveniet expedita, debitis, porro deserunt est illo eaque, ipsum
                      qui.
                      Explicabo perspiciatis. We
                      love to help your business.</p>
                    <p>consectetur adipisicing elit. Dolore rerum ratione quisquam, nisi nulla hic omnis eveniet
                      expedita, debitis, porro deserunt est illo eaque, ipsum qui. Explicabo perspiciatis.</p>
                  </div>
                </div>
              </div>
              <div class="panel panel-default wow slideInRight" data-wow-delay="0.6s">
                <div id="headingThree" class="panel-heading" role="tab">
                  <h4 class="panel-title">
                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="/#3" aria-expanded="false" aria-controls="3">隐私政策</a>
                  </h4>
                </div>
                <div id="3" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                  <div class="panel-body">
                    <p>We love to help your business, consectetur adipisicing elit. Dolore rerum ratione
                      quisquam,
                      nisi nulla hic omnis eveniet expedita, debitis, porro deserunt est illo eaque, ipsum
                      qui.
                      Explicabo perspiciatis. We
                      love to help your business.</p>
                    <p>consectetur adipisicing elit. Dolore rerum ratione quisquam, nisi nulla hic omnis eveniet
                      expedita, debitis, porro deserunt est illo eaque, ipsum qui. Explicabo perspiciatis.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="tab-panel">

            <ul class="nav nav-pills" role="tablist">
              <li role="presentation" class="active"><a href="/#discover" aria-controls="home" role="tab" data-toggle="tab">FAQ</a></li>
              <li role="presentation"><a href="/#development" aria-controls="profile" role="tab" data-toggle="tab">技术问题</a>
              </li>
              <li role="presentation"><a href="/#launch" aria-controls="profile" role="tab" data-toggle="tab">售后服务</a>
              </li>
            </ul>
            <div class="tab-content">
              <div id="discover" role="tabpanel" class="tab-pane active">
                <div class="row">
                  <div class="col-sm-12">
                    <p>发票说明</p>
                    <p>支持开具增值税普通发票（电子）及增值税专用发票。订单支付成功后，即可开具发票。
                      电子版发票类目：信息技术服务软件服务
                      专用发票类目：信息技术服务其他软件服务</p>
                  </div>
                  <div class="col-sm-4">
                    <h2>1500+</h2>
                    <strong>Hours of Codeing</strong>
                  </div>
                  <div class="col-sm-4">
                    <h2>1600+</h2>
                    <strong>Lins of codes</strong>
                  </div>
                  <div class="col-sm-4">
                    <h2>3500+</h2>
                    <strong>Features Added</strong>
                  </div>
                </div>
              </div>
              <div id="development" role="tabpanel" class="tab-pane">
                <div class="row">
                  <div class="col-md-5">
                    <img class="img-responsive" src="assets/blog03.jpg" alt="image" />
                  </div>
                  <div class="col-md-7">
                    <h1>Title goes Here</h1>
                    <p>We love to help your business, consectetur adipisicing elit. Dolore rerum ratione
                      quisquam,
                      nisi nulla hic omnis eveniet expedita.</p>
                  </div>
                </div>
              </div>
              <div id="launch" role="tabpanel" class="tab-pane">
                <div class="row">
                  <div class="col-sm-12">
                    <p>We love to help your business, consectetur adipisicing elit. Dolore rerum ratione
                      quisquam,
                      nisi nulla hic omnis eveniet expedita, debitis, porro deserunt est illo eaque, ipsum
                      qui.
                      Explicabo perspiciatis</p>
                    <p>We love to help your business, consectetur adipisicing elit. Dolore rerum ratione
                      quisquam,
                      nisi nulla hic omnis eveniet expedita, debitis, porro deserunt est illo eaque, ipsum
                      qui.
                      Explicabo perspiciatis.</p>
                  </div>
                  <div class="col-sm-4">
                    <h2>1500+</h2>
                    <strong>Hours of Codeing</strong>
                  </div>
                  <div class="col-sm-4">
                    <h2>1600+</h2>
                    <strong>Lins of codes</strong>
                  </div>
                  <div class="col-sm-4">
                    <h2>3500+</h2>
                    <strong>Features Added</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
