<!--
 * @Description: ================== footer =================
 * @Date: 2022-05-11 17:27:04
 * @Author: Zero
 * @FilePath: \beian-web\src\components\Footer.vue
 * @LastEditors: Zero
 * @LastEditTime: 2023-06-25 15:31:13
-->
<template>
  <!-- <div id="footer">
    <div>深圳市冰圈科技科技有限公司.Copyright2017版权所有</div>
    <div>备案编号: <a href="http://beian.miit.gov.cn/">粤ICP备19009828号</a></div>
    <div>联系地址:深圳市福田区莲花街道紫荆社区深南大道6006号华丰大厦1806-C58</div>
  </div> -->
  <section id="footer2" class="footer2">
    <div class="item dark">
      <div class="container">
        <div class="row">
          <div class="col-md-12 social">
            <ul class="list-inline">
              <li><h2>{{ host }}</h2></li>
              <li><span> 2023 | All right reserved.</span><br /></li>
              <li class="pull-right">
                <ul class="list-inline">
                  <li href="#"><i class="fa fa-facebook-official"></i></li>
                  <li href="#"><i class="fa fa-twitter"></i></li>
                  <li href="#"><i class="fa fa-google-plus"></i></li>
                  <li href="#"><i class="fa fa-dribbble"></i></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <!-- <div id="footer">
          <div>深圳市漫萌时代网络科技有限公司.</div>
          <div>备案编号: <a href="http://beian.miit.gov.cn/">粤ICP备2022131474号</a></div>
          <div>联系地址:深圳市罗湖区东门街道城东社区中兴路239号外贸集团大厦2806-H63</div>
        </div> -->
        <div id="footer">
          <div>深圳市一沙一粟科技有限公司.</div>
          <div>备案编号: <a href="http://beian.miit.gov.cn/">粤ICP备2021095642号</a></div>
          <div>联系地址:深圳市福田区莲花街道梅岭社区新闻路59号深茂商业中心10A-A78</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      host: location.host
    }
  }
}
</script>

<style scoped>
#footer {
  padding: 36px 0;
  /* text-align: center; */
  /* background-color: #222; */
  color: #fff;
  font-size: 14px;
}
a {
  color: #fff;
  text-decoration: underline;
}
div {
  margin-top: 8px;
}
</style>
