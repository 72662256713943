<!--
 * @Description: ================== 根据不同域名得到不同内容 =================
 * @Date: 2022-05-11 17:26:08
 * @Author: Zero
 * @FilePath: \beian-web\src\components\Page.vue
 * @LastEditors: Zero
 * @LastEditTime: 2023-06-25 16:24:19
-->
<template>
  <div id="page" class="page">
    <S1 v-if="modu.includes(1)" />
    <S2 v-if="modu.includes(2)" />
    <S3 v-if="modu.includes(3)" />
    <S4 v-if="modu.includes(4)" />
    <S5 v-if="modu.includes(5)" />
    <S6 v-if="modu.includes(6)" />
    <S7 v-if="modu.includes(7)" />
    <S8 v-if="modu.includes(8)" />
    <S9 v-if="modu.includes(9)" />
    <S10 v-if="modu.includes(10)" />
  </div>
</template>

<script>
import S1 from './Section/S1.vue'
import S2 from './Section/S2.vue'
import S3 from './Section/S3.vue'
import S4 from './Section/S4.vue'
import S5 from './Section/S5.vue'
import S6 from './Section/S6.vue'
import S7 from './Section/S7.vue'
import S8 from './Section/S8.vue'
import S9 from './Section/S9.vue'
import S10 from './Section/S10.vue'
export default {
  components: {
    S1,
    S2,
    S3,
    S4,
    S5,
    S6,
    S7,
    S8,
    S9,
    S10
  },
  data() {
    return {
      // host: '',
      hostList: { // 全部需要备案的域名
        'hb369game.com': [1, 2],
        '152wcr.com': [3, 4],
        'qlsybgame.com': [5],
        'mmcc666.com': [5, 8],
        'c2y5j8.com': [2, 5],
        '015zgh.com': [2, 5],
        '147gxy201.com': [6],
        'w250yac.com': [7],
        'ltby1122.com': [8, 9],
        'wudi669.com': [1, 5],
        'zs33qgb.com': [3, 5, 10],
        'fppd455.com': [1, 3, 7, 8],
        'lfcc88.com': [2, 8],
        '774egame.com': [4, 9, 3],
        'dspe156.com': [5, 1, 6],
        '614wgame.com': [7, 8, 9],
        'wlzz58.com': [3, 5, 7, 8],
        'Yote123.com': [2, 7, 9],
        'ot274ce.com': [2, 5, 7],
        '22eet.com': [2, 3, 5, 8, 9],
        '963tvc.com': [3, 6, 7],
        'lechenghy.com': [7, 5, 10],

        '192.168.50.82:8080': [3, 6, 7]
      },
      modu: [
        2,
        8
      ]
    }
  },
  created() {
    // this.host = location.href
    console.log(this.modu)
    console.log(location.host)
    for (const host in this.hostList) {
      // console.log(host)
      if (location.host.indexOf(host) !== -1) {
        this.modu = this.hostList[host]
        return
      }
    }
    console.log(this.modu)
    // if (this.hostList.includes(location.host)) {
    //   this.modu = this.hostList[location.host]
    // }

    // this.hostList.forEach(host => {
    //   console.log(host.length)
    // })
  }
}
</script>
