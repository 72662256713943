<!--
 * @Description: ==================  =================
 * @Date: 2022-05-12 11:37:41
 * @Author: Zero
 * @FilePath: \beian-web\src\components\Section\S5.vue
 * @LastEditors: Zero
 * @LastEditTime: 2022-05-12 11:40:59
-->
<template>
  <section id="content-section9" class="content-section9">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 padding-0">
          <div class="content-iphone">
            <img class="img-responsive" src="assets/bg0.jpg" alt="iphone" />
            <i class="fa fa-play play red-gradient"></i>
          </div>
        </div>
        <div class="col-md-6">
          <div class="single-content">
            <h3>我们的价值观</h3>
            <p>
              精挑细选，把控品质 拥抱变化，勇于创新。
            </p>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
</template>
