<!--
 * @Description: ==================  =================
 * @Date: 2022-05-12 11:37:41
 * @Author: Zero
 * @FilePath: \beian-web\src\components\Section\S6.vue
 * @LastEditors: Zero
 * @LastEditTime: 2022-05-12 11:41:23
-->
<template>
  <section id="content-section13" class="content-section13">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-md-offset-2 text-center">
          <h1 class="section-title">核心团队</h1>
          <p class="section-para">作为“360度”环绕式服务发行商，我们用实际行动深耕：服务、IP精品、发行为核心的生态体系，围绕玩家，不断提供精品IP好游戏，落地走心服务。</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-leader-wrapper text-center">
            <div class="single-leader">
              <img class="img-circle" src="assets/member5.jpg" alt="team" />
              <div class="leader-overflow text-left">
                <strong>John Doe</strong>
                <div>CEO / Co - founder <i class="fa fa-long-arrow-right warning-ebg pull-right"></i>
                </div>
              </div>
            </div>
            <div class="leader-detail">
              <ul class="list-inline leader-links">
                <li><i class="fa fa-facebook"></i></li>
                <li><i class="fa fa-twitter"></i></li>
                <li><i class="fa fa-google-plus"></i></li>
                <li><i class="fa fa-dribbble"></i></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-leader-wrapper text-center">
            <div class="single-leader">
              <img class="img-circle" src="assets/member6.jpg" alt="team" />
              <div class="leader-overflow text-left">
                <strong>Patrices</strong>
                <div>SEO / Web Designer <i class="fa fa-long-arrow-right primary-ebg pull-right"></i>
                </div>
              </div>
            </div>
            <div class="leader-detail">
              <ul class="list-inline leader-links">
                <li><i class="fa fa-facebook"></i></li>
                <li><i class="fa fa-twitter"></i></li>
                <li><i class="fa fa-google-plus"></i></li>
                <li><i class="fa fa-dribbble"></i></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-leader-wrapper text-center">
            <div class="single-leader">
              <img class="img-circle" src="assets/member7.jpg" alt="team" />
              <div class="leader-overflow text-left">
                <strong>Henricson</strong>
                <div>CMO / Developer <i class="fa fa-long-arrow-right success-ebg pull-right"></i></div>
              </div>
            </div>
            <div class="leader-detail">
              <ul class="list-inline leader-links">
                <li><i class="fa fa-facebook"></i></li>
                <li><i class="fa fa-twitter"></i></li>
                <li><i class="fa fa-google-plus"></i></li>
                <li><i class="fa fa-dribbble"></i></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-leader-wrapper text-center">
            <div class="single-leader">
              <img class="img-circle" src="assets/member8.jpg" alt="team" />
              <div class="leader-overflow text-left">
                <strong>Richard</strong>
                <div>ACM / Menager <i class="fa fa-long-arrow-right danger-ebg pull-right"></i></div>
              </div>
            </div>
            <div class="leader-detail">
              <ul class="list-inline leader-links">
                <li><i class="fa fa-facebook"></i></li>
                <li><i class="fa fa-twitter"></i></li>
                <li><i class="fa fa-google-plus"></i></li>
                <li><i class="fa fa-dribbble"></i></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 text-center"><br />
          <a class="ebtn danger-ebtn">查看更多</a>
        </div>
      </div>
    </div>
  </section>
</template>
