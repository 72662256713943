<!--
 * @Description: ==================  =================
 * @Date: 2022-05-12 11:37:41
 * @Author: Zero
 * @FilePath: \beian-web\src\components\Section\S9.vue
 * @LastEditors: Zero
 * @LastEditTime: 2022-05-12 11:43:16
-->
<template>
  <section id="content-section5" class="content-section5">

    <div class="intro center-content skyblue-gradient">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1 col-xs-12">
            <h1 class="text-center">Newsletter</h1>
            <form class="login-reg subscribe text-center form-inline">
              <div class="row margin-bottom-0">
                <div class="col-md-12 col-sm-12">
                  <div class="form-group">
                    <input id="exampleInputEmail2" class="form-control input-lg" placeholder="您的邮箱：" type="email" />
                  </div>
                  <button type="submit" class="btn btn-info button">订阅</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
