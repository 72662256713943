<!--
 * @Description: ==================  =================
 * @Date: 2022-05-12 11:37:41
 * @Author: Zero
 * @FilePath: \beian-web\src\components\Section\S4.vue
 * @LastEditors: Zero
 * @LastEditTime: 2022-05-12 11:40:39
-->
<template>
  <section id="feature4" class="feature4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 padding-0">
          <div class="content-iphone">
            <img class="img-responsive" src="assets/bg0.jpg" alt="iphone" />
            <!--i class="fa fa-play play red-gradient"></i-->
          </div>
        </div>
        <div class="col-md-5">
          <div class="single-content">
            <h3>我们的使命</h3>
            <p>
              发行精品游戏，为用户传递快乐。
            </p>
            <a class="btn btn-info button" href="/#">了解更多</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <div class="single-content">
            <h3>我们的愿景</h3>
            <p>
              致力于全球游戏发行，用快乐链接全世界的游戏用户，让游戏成为世界语言。
            </p>
            <a class="btn btn-info button" href="/#">了解更多</a>
          </div>
        </div>
        <div class="col-md-6 col-md-offset-1 padding-0">
          <div class="content-iphone">
            <img class="img-responsive" src="assets/bg0.jpg" alt="iphone" />
            <!-- <i class="fa fa-play play red-gradient"></i> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
