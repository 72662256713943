<!--
 * @Description: ==================  =================
 * @Date: 2022-05-12 11:37:41
 * @Author: Zero
 * @FilePath: \beian-web\src\components\Section\S3.vue
 * @LastEditors: Zero
 * @LastEditTime: 2022-05-12 11:40:08
-->
<template>
  <section class="counter1">
    <div class="row">
      <div class="col-md-3 info-area text-center">
        <div>
          <h4>78</h4>
          <h3><i class="lnr lnr-apartment"></i>Members</h3>
        </div>
      </div>
      <div class="col-md-3 info-area text-center">
        <div>
          <h4>46</h4>
          <h3><i class="lnr lnr-users"></i>Reviews</h3>
        </div>
      </div>
      <div class="col-md-3 info-area text-center">
        <div>
          <h4>56</h4>
          <h3><i class="lnr lnr-user"></i>Products</h3>
        </div>
      </div>
      <div class="col-md-3 info-area text-center">
        <div>
          <h4>76</h4>
          <h3><i class="lnr lnr-graduation-hat"></i>Awwards</h3>
        </div>
      </div>
    </div>
  </section>
</template>
