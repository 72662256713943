<template>
  <section id="content-section12" class="content-section12">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="media single-content">
            <div class="media-left pull-left">
              <i class="fa fa-wordpress skyblue-gradient"></i>
            </div>
            <div class="media-body">
              <h3>2018 6月</h3>
              <p>
                正式成立，团队人数8人
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="media single-content">
            <div class="media-left pull-left">
              <i class="fa fa-joomla red-gradient"></i>
            </div>
            <div class="media-body">
              <h3>2018 9月</h3>
              <p>
                旗下项目达到百款以上
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="media single-content">
            <div class="media-left pull-left">
              <i class="fa fa-cog blue-gradient"></i>
            </div>
            <div class="media-body">
              <h3>2019 3月</h3>
              <p>
                破千万月流水
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="media single-content">
            <div class="media-left pull-left">
              <i class="fa fa-cog blue-gradient"></i>
            </div>
            <div class="media-body">
              <h3>2019 5月</h3>
              <p>
                合作公司规模破百家
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="media single-content">
            <div class="media-left pull-left">
              <i class="fa fa-cog skyblue-gradient"></i>
            </div>
            <div class="media-body">
              <h3>2019 9月</h3>
              <p>
                世界级IP产品合作数突破30款
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="media single-content">
            <div class="media-left pull-left">
              <i class="fa fa-cog red-gradient"></i>
            </div>
            <div class="media-body">
              <h3>2019 10月</h3>
              <p>
                旗下项目达到300款以上
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="media single-content">
            <div class="media-left pull-left">
              <i class="fa fa-wordpress skyblue-gradient"></i>
            </div>
            <div class="media-body">
              <h3>2019 11月</h3>
              <p>
                破五千万月流水
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="media single-content">
            <div class="media-left pull-left">
              <i class="fa fa-joomla red-gradient"></i>
            </div>
            <div class="media-body">
              <h3>2020 4月</h3>
              <p>
                公司规模150人
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="media single-content">
            <div class="media-left pull-left">
              <i class="fa fa-cog blue-gradient"></i>
            </div>
            <div class="media-body">
              <h3>2022 2月</h3>
              <p>
                持续发展。。。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
