<!--
 * @Description: ================== header =================
 * @Date: 2022-05-11 17:26:55
 * @Author: Zero
 * @FilePath: \beian-web\src\components\Header.vue
 * @LastEditors: Zero
 * @LastEditTime: 2023-06-08 10:39:35
-->
<template>
  <header id="header15" ref="header" class="header15">

    <nav ref="nav" role="navigation" class="navbar navbar-default">
      <div class="container">
        <div class="navbar-header">
          <button data-target="#navbar-collapse-02" data-toggle="collapse" class="navbar-toggle" type="button">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a href="/#" class="navbar-brand brand">
            <i class="fa fa-superpowers"></i>
            一沙一粟
          </a>
        </div>

        <div id="navbar-collapse-02" class="collapse navbar-collapse">
          <ul class="nav navbar-nav navbar-right">
            <li class="active"><a href="/#">首页</a></li>
            <li><a href="/#">关于我们</a></li>
            <li><a href="/#">团队</a></li>
            <li><a href="/#">博客</a></li>
            <li><a href="/#">联系我们</a></li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="intro center-vertically">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="header-content text-center">
              <div class="svg-logo"><img class="img-responsive center-block" src="assets/Logo-13-white.svg" alt="Watch Video" /></div>
              <h1 class="margin-auto">{{ host }}</h1>
              <p>诚信为本、客户至上。</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      host: location.host,
      navColors: [
        // 'transparent',
        // '#ccc',
        'dodgerblue',
        // '#ffbc42',
        // 'green',
        // '#EF0000',
        'blue',
        // '#01bf86',
        // '#ef476f',
        '#01BF86'
      ]
    }
  },
  mounted() {
    const rnd = Math.floor(Math.random() * 3 + 1)
    // const rndStr = rnd < 3 ? '0' + rnd : rnd
    this.$refs['header'].style.background = `url(/images/background/bg0${rnd}.jpeg)`
    this.$refs['header'].style.backgroundRepeat = 'no-repeat'
    this.$refs['header'].style.backgroundSize = '100% 100%'
    this.$refs['nav'].style.background = this.navColors[rnd]
  }
}
</script>
